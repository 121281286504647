<template>
  <div style="width: 100%">
    <div class="container">
      <page-header-image />
      <div style="margin-top: 100px"></div>
      <div class="contentContainer">
        <aside class="slideBar">
          <div class="slidebarItem active">
            <p class="title">乐慧邦科技（深圳） 有限公司</p>
            <img
              class="vector activeVector"
              src="..\static\img\Vector.png"
              alt=""
            />
          </div>
        </aside>
        <main class="content">
          <img class="logo"
            src="..\static\img\logo2.png"
            alt="LOGO"
          />
          <article>
            <p style="text-indent: 2em">
              乐慧邦科技（深圳）有限公司隶属于宝德仕电玩制造（深圳）有限公司的全资子公司，于2019年3月成立深圳市坪山区。为响应国家及深圳市产业升级政策，公司是一间专注于研发、设计、创新为主的科技型公司。主要研发项目方向包括：人体健康按摩器具、医疗卫生辅助类产品、高科技电子类产品、电子医疗器械、AI人工智能、集成电路及日用小家电的设计、研发和经营，信息技术咨询等主要业务。公司现已获得实用新型专利4件，已获备案发明专利3件。
            </p>
            <br />
            <p style="text-indent: 2em">
              公司成立的愿景是致力于高科技电子线路板、人工智能、人体健康及医疗器械产业方向的设计及研发。
            </p>
            <br />
            <p style="text-indent: 2em">
              公司目标：在五年内获得5项以上国家发明及实用新型专利，及通过国家高新技术企业认证；十年内达到深圳市坪山区排名靠前的高新技术专利发明企业。
            </p>
            <br />
            <p style="text-indent: 2em">
              公司现有办公面积300多平方米﹐写字楼位于在政府产业区创新广场C座。公司组建有高学历及高素质的工程开发及原创设计团队。
            </p>
            <br />
            <p style="text-indent: 2em">
              公司管理系统采用ISO9001:
              2015质量管理体系模式。为广大客户提供高端的、高品质的、满意的产品设计和服务是乐慧邦不懈的奋斗目标。为创造更多更好的专利是我们不懈努力的愿景和目标。
            </p>
            <br /><br /><br />

            <p>
              办公地址：深圳市坪山区坪山大道2007号创新广场C座C1921-26单元
            </p>
            <p>联系人：谢建中先生</p>
            <p>电话:(0755)61862318</p>
            <p>传真:(0755)61862319</p>
          </article>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderImage from "../components/PageHeaderImage.vue";

export default {
  components: { PageHeaderImage },
  data() {
    return {
      isActive: 0,
    };
  },
  methods: {
    setActive(data) {
      this.isActive = data;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 70px;
  position: relative;
  left: 5%;
  width: 90%;
}

.slideBar {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 476.25px;
  width: 100%;
}

.slidebarItem {
  position: relative;
  width: 100%;
  height: 80px;
  background: #1f89eb;
  margin-top: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  /*   line-height: 80px; */
  color: #ffffff;
  padding-left: 30px;
  text-align: start;
  transition: all 0.5s;
  cursor: pointer;
  padding-top: 20px;
}

.title{
  width: 80%;
}

.logo{
  height: 206.25px;
}

.active {
  background: #041d72;
  transition: all 0.5s;
  height: 206.25px;
}

.vector {
  position: absolute;
  right: 30px;
  top: 24px;
  height: 32px;
  transition: all 0.5s;
}

.activeVector {
  transform: rotate(90deg);
}

.content {
  position: relative;
  width: 100%;
  transition: all 0.5s;
  margin-bottom: 50px;
}

.picture {
  display: block;
  margin: auto;
  width: 75%;
  margin-bottom: 50px;
}

h2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
}

.detailContainer {
  width: inherit;
  display: none;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #0e0e0e;
  opacity: 0;
  transition: all 0.5s;
  margin-top: 10px;
}

.activeContent {
  opacity: 1;
  transition: all 0.5s;
  display: inline-block;
}

@media screen and (max-width: 1450px) {
  .contentContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .slideBar {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 80px;
    width: 100%;
    margin-bottom: 30px;
  }

  .slidebarItem {
    margin-top: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;
  }

  .vector {
    display: none;
  }

  .active {
    background: #041d72;
    transition: all 0.5s;
    height: 80px;
    line-height: 80px;
  }

  .title{
    width: 100%;
  }

  .logo{
    display: block;
    height: 150px;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .slidebarItem {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }

  .slideBar {
    height: 60px;
    margin-bottom: 15px;
  }
}
</style>
