<template>
    <div class="headerImageContainer">
        <img class="Image" src="../static/img/showImg1.jpg" alt="">
        <div class="filter"></div>
    </div>
</template>
<script>
export default {

};
</script>

<style scoped>
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    .headerImageContainer{
        position: relative;
        width: 100%;
        /* width: 100vw; */
    }
    .Image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;
        overflow: hidden;
        z-index: -1;
    }
    .filter{
        background: rgba(4, 29, 114, 0.3);
        width: 100%;
        height: 600px;
    }
    /*手机端适配*/
@media screen and (max-width: 800px) {
  .headerImageContainer,
  .Image,
  .filter{
    width: 100%;
    height: 300px;
    padding: 0;
  }
}
</style>
